import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Hero } from '../components/Hero';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Panels } from '../components/Panels';
import { Seo } from '../components/Seo';

const StyledTextMain = styled.div``;

// ask smiley
const AltTemplate: React.FC = ({ data: { service, groupItems } }) => {
    const filteredItems = groupItems.edges.filter(item => {
        return item.node.frontmatter.title !== service.frontmatter.title;
    });
    return (
        <Fragment>
            <Seo
                title={service.frontmatter.meta.title}
                description={service.frontmatter.meta.description}
            />
            <Hero type={service.frontmatter.type} title={service.frontmatter.title}>
                <GatsbyImage
                    className="hero-image"
                    image={service.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
                    alt={service.frontmatter.thumbnail.name}
                />
            </Hero>
            <Panels>
                {filteredItems.map(service => {
                    return (
                        <Link
                            key={service.node.frontmatter.title}
                            to={service.node.frontmatter.slug}
                        >
                            <GatsbyImage
                                alt={service.node.frontmatter.thumbnail.name}
                                image={
                                    service.node.frontmatter.thumbnail.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                            <p>{service.node.frontmatter.title}</p>
                        </Link>
                    );
                })}
            </Panels>
        </Fragment>
    );
};

export const query = graphql`
    query ($category: String, $id: String!) {
        service: mdx(id: { eq: $id }) {
            frontmatter {
                meta {
                    title
                    description
                }
                title
                slug
                type
                list
                category
                thumbnail {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                    name
                }
                images {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                    name
                }
            }
            body
        }
        groupItems: allMdx(
            sort: { order: ASC, fields: frontmatter___type }
            filter: { frontmatter: { category: { eq: $category } } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        type
                        images {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                        title
                        slug
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                    }
                }
            }
        }
    }
`;
AltTemplate.Layout = DefaultLayout;

export default AltTemplate;
